<template>
  <div class="main-container">
    <el-form inline>
      <el-form-item>
        <el-date-picker v-model="reportDate" value-format="yyyyMMdd" format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleExport">导出Excel</el-button>
      </el-form-item>
    </el-form>
    <div class="excel-container" ref="excelContainer"></div>
  </div>
</template>

<script>
import auth from '@/common/auth'
import config from '@/config'
import axios from 'axios'
import moment from 'moment'

const fileSaver = require('file-saver')

export default {
  data () {
    return {
      projectGuid: auth.getUserInfo().projectGuid,
      reportDate: moment().format('YYYYMMDD'),
      list: null
    }
  },
  watch: {
    async reportDate (newVal) {
      if (newVal) {
        this.$loading()
        await this.getData()
        this.initSpread()
        this.$loading().close()
      }
    }
  },
  methods: {
    handleExport () {
      if (!this.excelIo) {
        this.excelIo = new GC.Spread.Excel.IO()
      }
      this.excelIo.save(this.workbook.toJSON(), blob => {
        fileSaver.saveAs(blob, `${this.reportDate}.xlsx`)
      })
    },
    async getData () {
      try {
        const response = await axios.get(`${config.restHost}/g181/tenderStat2`, {
          params: {
            projectGuid: this.projectGuid,
            reportDate: this.reportDate
          }
        })
        this.list = response.data.map(row => row.map(item => Number(item) === 0 ? null : item))

        this.$message({
          type: 'success',
          message: '加载成功！'
        })
      } catch (err) {
        console.log(err)
        this.$message({
          type: 'error',
          message: '操作失败'
        })
      }
    },
    initSpread () {
      if (!this.workbook) {
        this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
      }
      this.workbook.getActiveSheet().setRowCount(this.list.length)
      this.workbook.getActiveSheet().setColumnCount(this.list[0].length)
      this.workbook.getActiveSheet().setArray(0, 0, this.list)
      const colCount = this.workbook.getActiveSheet().getColumnCount()

      for (let colIndex = 0; colIndex < colCount; ++colIndex) {
        if (this.list[0][colIndex] === this.list[1][colIndex]) {
          this.workbook.getActiveSheet().addSpan(0, colIndex, 2, 1)
        }
      }

      let prevColIndex = 0
      let currentColIndex = 0
      let colIndex = 1
      for (colIndex = 1; colIndex < colCount; ++colIndex) {
        if (this.list[0][colIndex] === this.list[0][currentColIndex]) {
          currentColIndex = colIndex
        } else {
          console.log(this.list[0][colIndex], prevColIndex, currentColIndex)
          if (currentColIndex - prevColIndex > 0) this.workbook.getActiveSheet().addSpan(0, prevColIndex, 1, currentColIndex - prevColIndex + 1)
          prevColIndex = colIndex
          currentColIndex = prevColIndex
        }
      }
      if (currentColIndex - prevColIndex > 0) this.workbook.getActiveSheet().addSpan(0, prevColIndex, 1, currentColIndex - prevColIndex + 1)

      var style = new GC.Spread.Sheets.Style()
      style.hAlign = GC.Spread.Sheets.HorizontalAlign.center
      style.vAlign = GC.Spread.Sheets.VerticalAlign.center
      this.workbook.getActiveSheet().setStyle(0, -1, style)
      this.workbook.getActiveSheet().setStyle(1, -1, style)

      this.workbook.getActiveSheet().getRange(0, 0, this.list.length, colCount)
        .setBorder(new GC.Spread.Sheets.LineBorder('#000000', GC.Spread.Sheets.LineStyle.thin), { all: true })

      for (let i = 0; i < colCount; ++i) {
        this.workbook.getActiveSheet().autoFitColumn(i)
      }
    }
  },
  async mounted () {
    this.$loading()
    await this.getData()
    this.initSpread()
    this.$loading().close()
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .excel-container {
    flex: 1;
    overflow: hidden;
  }
}
</style>
